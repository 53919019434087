import { Stack } from '@mui/material'
import { memo } from 'react'
import theme from 'src/theme/theme'

export default memo(function BrandBg() {
  return (
    <Stack
      id="left"
      height={'100%'}
      alignItems="center"
      justifyContent="center"
      flex={1}
      bgcolor={theme.palette.primary.main}
      sx={{ '@media (max-width: 1024px)': { display: 'none' } }}
    >
      <svg height="15rem" color="#fff" viewBox="0 0 464 403" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          id="logo-word"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M363.5 260H402.5V369.5H463.5V403H370.5V310.432L311.965 403L221 260H261.5L311.965 339L363.5 260Z"
          fill="currentColor"
        />
        <path
          id="logo-box"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 0.5H402.5V214.5H368.5V34.5H34.5V368.5H237L259 402.5H0.5V0.5Z"
          fill="currentColor"
        />
      </svg>
    </Stack>
  )
})
