import dynamic from "next/dynamic"
import { useRouter } from 'next/router'
import { Fragment, useEffect, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { useFormik } from 'formik'
import Link from 'next/link'
import { Typography, TextField, Stack, IconButton, FormLabel } from '@mui/material'
import { Lock, Visibility, VisibilityOff } from '@mui/icons-material'

import loginValidationSchema, { loginInitialValues } from '../src/validationSchemas/loginSchema'
import useLogin from 'src/hooks/useLogin'
import VLAlert from 'src/components/common/VLAlert'
import { getCookie } from 'src/helpers/queryHelpers'
import theme from 'src/theme/theme'
import BrandBg from 'src/components/BrandBg'
import useRbac, { UserRoleType } from 'src/hooks/useRbac'

const LoginFormConfig = [
  {
    name: 'username',
    label: 'Username',
    placeholder: 'user@viewlift.com',
    type: 'email',
    fieldType: 'text',
  },
  {
    name: 'forgotUsername',
    label: 'Forgot Username?',
    type: 'link',
    href: '/forgot-password',
  },
  {
    name: 'password',
    label: 'Password',
    placeholder: '••••••••••',
    type: 'password',
    fieldType: 'text',
  },
  {
    name: 'forgotPassword',
    label: 'Forgot Password?',
    type: 'link',
    href: '/forgot-password',
  },
]

const SocialLogin = dynamic(() => import('src/components/SocialLogin'))

export default function Home() {
  const [showPassword, setShowPassword] = useState(false)
  const { handleLogin, loading, message, setMessage, loginWithSocial} = useLogin()
  const {getRedirectionUrl} = useRbac()

  const router = useRouter()
  const formik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      handleLogin(values)
    },
  })
  useEffect(() => {
    const token = getCookie('vl-accessToken')
    const roles = getCookie("userRoles")
    if (token && roles) {
      router.replace(getRedirectionUrl(JSON.parse(roles) as UserRoleType))
    }
  }, [router, getRedirectionUrl])
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="center" height="100vh">
        <BrandBg />
        <Stack id="right" flex={1.3} alignItems="center" justifyContent="center" padding={2}>
          <Stack
            component="form"
            id="form"
            padding={4}
            maxWidth="32rem"
            width="100%"
            borderRadius={2}
            gap={3}
            boxShadow="0 0 8px 2px #f6f6f9"
            border={`1px solid ${theme.palette.background.paper}`}
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <Stack gap={2}>
              <Stack p={2} bgcolor={'rgba(12, 81, 119, 0.04)'} borderRadius={1} width={'fit-content'} justifyContent={'center'} alignItems={'center'}>
                <Lock fontSize="small" color="primary" />
              </Stack>
              <Typography variant="h5" fontWeight={700} color={theme.palette.primary.main}>
                Login
              </Typography>
            </Stack>
            <Stack id="fields">
              {LoginFormConfig.map(({ label, name, type, fieldType, href, placeholder }) => {
                if (fieldType === 'text')
                  return (
                    <Fragment key={name}>
                      <FormLabel htmlFor={name} style={{ fontSize: '90%', color: '#666', marginBottom: "0.3rem" }} required>
                        {label}
                      </FormLabel>
                      <TextField
                        required
                        autoFocus
                        name={name}
                        id={name}
                        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
                        size="small"
                        // label={label}
                        placeholder={placeholder}
                        value={formik.values[name as keyof typeof formik.values]}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched[name as keyof typeof formik.values] && formik.errors[name as keyof typeof formik.values])}
                        helperText={formik.touched[name as keyof typeof formik.values] && formik.errors[name as keyof typeof formik.values]}
                        InputProps={{
                          endAdornment:
                            type === 'password' ? (
                              <IconButton onClick={() => setShowPassword(!showPassword)} size="small" color="default">
                                {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                              </IconButton>
                            ) : (
                              false
                            ),
                        }}
                      />
                    </Fragment>
                  )
                if (type === 'link' && href)
                  return (
                    <Link
                      key={name}
                      href={href}
                      style={{ cursor: 'pointer', fontSize: '90%', textAlign: 'right', width: 'fit-content', margin: '0 0 1rem auto' }}
                    >
                      {label}
                    </Link>
                  )
              })}
              <LoadingButton variant="contained" disableElevation loading={loading} type="submit">
                Login
              </LoadingButton>
            </Stack>
            <SocialLogin onLogin={loginWithSocial} loading={loading} separatorPosition='top' />
            <Typography variant="body2" marginTop={3} color="#666">
              This site is protected by reCAPTCHA and the Google{' '}
              <Link
                href="https://policies.google.com/privacy"
                passHref
                target="_blank"
                style={{ fontWeight: 600, color: theme.palette.primary.main }}
              >
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link href="https://policies.google.com/terms" passHref style={{ fontWeight: 600, color: theme.palette.primary.main }}>
                Terms of Service
              </Link>{' '}
              apply.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <VLAlert message={message} setMessage={setMessage} />
    </>
  )
}
